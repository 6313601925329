<template>
  <Chart
  type="doughnut"
  :width="width"
  :height="height"
  :data="data"
  :options="options"
  />
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store'

export default defineComponent({
  props: {
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    laData: {
      type: Array,
      default: () => ([])
    },
    losLabels: {
      type: Array,
      default: () => ([''])
    },
    optionsCart: {
      type: Object,
      default: () => ({})
    }
  },
  setup (props, context) {
    const store = useStore()
    const darkMode = computed(() => store.state.main.darkMode)
    const data = computed(() => {
      return {
        // labels: ['Cumplido', 'Por cumplir'],
        labels: props.losLabels ? props.losLabels : [],
        datasets: [
          {
            data: props.laData,
            backgroundColor: ['#285FD3', '#FF8B26', '#FFC533'],
            hoverBackgroundColor: ['#FF8B26', '#FFC533', '#285FD3'],
            borderWidth: 2,
            borderColor: darkMode.value ? '#303953' : '#fff'
          }
        ]
      }
    })
    const options = computed(() => {
      var opt = {}
      if (Object.keys(props.optionsCart).length !== 0) {
        opt = props.optionsCart
      } else {
        opt = {
          tooltips: props.losLabels ? { trigger: 'axis' } : { enabled: false },
          legend: {
            display: false
          },
          cutoutPercentage: 53
          }
        }
      return opt
    })

    return {
      data,
      options
    }
  }
})
</script>
