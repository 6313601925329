<template>
  <div class="box p-5 zoom-in scroll-ajuste h-full" @click="handleShow">
    <!-- <p class="font-medium" style="font-size: 16px">Facturación</p> -->
    <template v-if="!verMas">
      <p class="font-medium mb-2 text-center" style="font-size: 16px">Pedidos</p>
      <div class="grid grid-cols-2 gap-4" >
        <div class=" gap-4">
          <div class="flex flex-col">
              <span class="font-medium">
                Cant Pedidos:
              </span>
              <span class="text-gray-600">
              {{dataPedidos.CantPedidos}}
              </span>
          </div>
          <div class="flex flex-col">
              <span class="font-medium">
                Ref Solicitadas:
              </span>
              <span class="text-gray-600">
              {{dataPedidos.RefSoli}}
              </span>
          </div>
          <div class="flex flex-col">
              <span class="font-medium">
                Ref Entregadas:
              </span>
              <span class="text-gray-600">
              {{dataPedidos.RefEntr}}
              </span>
          </div>
        </div>
        <div class=" gap-4">
          <!-- chart -->
          <div class="flex flex-col">
              <span class="font-medium">
                  % Referencias Entregadas
              </span>
              <div class="flex-none mx-auto relative">
                  <ReportDonutChart1
                  :width="120"
                  :height="120"
                  :laData="[
                      (dataPedidos.RefEntr * 100) /(dataPedidos.RefSoli),
                      100 - (dataPedidos.RefEntr * 100) /(dataPedidos.RefSoli)
                  ]"
                  :losLabels="[
                      'Entregados', 'Pendientes'
                  ]"
                  />
                  <div
                  class="font-medium absolute w-full h-full flex items-center justify-center top-0 left-0"
                  style="z-index:-1;"
                  >
                      {{Math.round((dataPedidos.RefEntr * 100) /(dataPedidos.RefSoli))}}%
                  </div>
              </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="flex items-center justify-between w-full mb-2">
        <div class="font-medium truncate text-sm">Detalle de Referencias</div>
      </div>
      <div class="w-full overflow-x-auto letra">
        <div class="w-full flex">
          <div v-for="(row, k) in detallePedidos"
          :key="k" class="font-medium text-center" style="width:32%;">{{row.tipo}}</div>
        </div>
        <div class="w-full flex flex-col">
          <div class="flex w-full">
            <div
              v-for="(row, k) in detallePedidos"
            :key="k"
              class="mb-1 text-primary-1 dark:text-white capitalize text-center"
              style="width:32%;"
            >
              {{ row.cant }}
              <!-- {{ listMes(row.mes-1, 1) }} -->
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import storeCardPedidos from '../store/cardPedidos.store'
import storeFiltro from '../store/filtro.store'
import ReportDonutChart1 from '@/components/report-donut-chart-1/Main.vue'
import dayjs from 'dayjs'
import { computed, ref, watch } from 'vue'
export default {
  name: 'cardPedidos',
  components: { ReportDonutChart1 },
  setup () {
    /** data **/
    const verMas = ref(false)
    const VlrContratado = ref(7111000000)
    /** computed **/
    const dataPedidos = computed(
      () => storeCardPedidos.getters.dataPedidos
    )
    const anio = computed(() => storeFiltro.getters.anio)
    const nameMes = computed(() =>
      dayjs(dayjs().month(storeFiltro.getters.mes)).format('MMMM')
    )
    const mes = computed(() => storeFiltro.getters.mes)
    const detallePedidos = computed(
      () => storeCardPedidos.getters.detallePedidos
    )
    /** watch **/
    watch(verMas, (a, b) => {
      if (b) {
        getData()
      } else {
        getDetalle()
      }
    })
    watch(anio, () => {
      getDetalle()
    })
    /** methods **/
    const listMes = (mes, num) => {
        if (num === 1) {
            return dayjs(dayjs().month(mes)).format('MMM')
        } else {
            return dayjs(dayjs().month(mes)).format('MMMM')
        }
    }
    const handleShow = () => {
      verMas.value = !verMas.value
    }
    const getDetalle = () => {
      storeCardPedidos.dispatch('getDetallePedidos', {
        anio: anio.value
      })
    }
    const getData = () => {
      storeCardPedidos.dispatch('getDataPedidos', {
        anio: anio.value,
        mes: mes.value
      })
    }
    return {
      VlrContratado,
      dataPedidos,
      anio,
      mes,
      handleShow,
      listMes,
      verMas,
      nameMes,
      detallePedidos
    }
  }
}
</script>
<style scoped></style>
